@import "~@/styles/variables";








































































































































.delivery-icon {
  width: 25px;
}
